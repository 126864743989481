<template>
  <div class="bubble" :style="myStyle">
    <div
      :class="{
        left: direction == 'left',
        right: direction == 'right',
        leftBottom: direction == 'leftBottom',
        rightBottom: direction == 'rightBottom',
      }"
      :style="myStyle"
    ></div>
    <div>{{ content.text ? content.text.Text : content }}</div>
    <div class="link" v-if="content['link-web']">
      <span class="link-title">{{ content["link-web"].Label }}</span>
      <a class="link-web" :href="content['link-web'].Text">{{
        content["link-web"].Text
      }}</a>
      <div class="problems">
        <div
          class="item"
          v-for="(item, index) in content['link-web'].Options"
          :key="index"
          @click="sendNewProblems(item)"
        >
          <span class="index">{{ index + 1 }}.</span>
          <span class="item-text">{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="outer" v-if="content['image']">
      <img class="image" :src="imageUrl" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { sendDialogAI, sendDialogBE } from "../../api/dialog";
import Cookies from "js-cookie";
import { pre_imageUrl } from "../../utils/config";

export default {
  name: "Bubble",
  props: {
    bgcolor: {
      type: String,
      default: "#fff",
    },
    direction: {
      type: String,
      default: "left",
    },
    fcolor: {
      type: String,
      default: "#444954",
    },
    content: {
      default: {},
    },
  },
  data() {
    return {
      myStyle: {
        backgroundColor: this.bgcolor,
        color: this.fcolor,
        imageUrl: "",
      },
      value: "",
    };
  },
  computed: {
    ...mapState("dialog", ["sessionData", "sessionId", "userId"]),
  },
  created() {
    if (this.content["image"]) {
      this.imageUrl = this.content["image"].Text.replace("/.", pre_imageUrl);
    }
  },
  methods: {
    ...mapMutations("dialog", ["ADD_SESSION_DATA", "SET_SESSION_ID"]),
    // 发送新问题
    async sendNewProblems(message) {
      this.ADD_SESSION_DATA({
        isUser: true,
        type: 1,
        text: message,
        message_id: "0",
      });
      let robotText = null;
      let robotExtra = [];
      if (message === "查看更多问题" && this.extra.length) {
        robotText = {
          LeadingMessage: "您是否想问：",
          EndingMessage:
            "请点击对应的选项进行查看。如果以上问题不是您想要的，请重新描述您的问题。",
          Label: null,
          Text: null,
          Options: [...this.extra],
        };
      } else {
        // 接入对象引擎
        const { queryResult: res, sessionId } = await sendDialogAI({
          sessionId: this.sessionId,
          agentId: "8c457b8f-fd5f-4c95-979d-b1ccbffed775",
          actionAreaId: "default",
          channelId: "default",
          input: {
            query: {
              queryText: message,
            },
          },
        });
        // 更新对话ID
        this.SET_SESSION_ID(sessionId);
        let answer = {};
        res.responseText.forEach((value, index) => {
          answer[value.type.toLowerCase()] = JSON.parse(value.content);
        });
        robotText = answer;
        for (let item of res.outputContexts) {
          if (item.name.indexOf("dymextra") != -1) {
            let arr = [];
            for (let item of res.outputContexts[2]?.options) {
              item.displayText && arr.push(item.displayText);
            }
            robotExtra = arr;
            break;
          }
        }
      }

      const { data } = await sendDialogBE({
        user_id: this.userId,
        open_id: Cookies.get("open_id"),
        session_id: this.sessionId,
        question: message,
        answer: JSON.stringify(robotText),
      });

      if (robotText.text.Options && robotText.text.Options.length > 0) {
        if (robotText.text.Text) {
          this.ADD_SESSION_DATA({
            isUser: false,
            type: 1,
            text: robotText.text.Text,
            message_id: data.id,
            extra: [],
          });
        }
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 2,
          text: robotText.text.Options,
          message_id: data.id,
          extra: [...robotExtra],
        });
      } else {
        this.ADD_SESSION_DATA({
          isUser: false,
          type: 1,
          text: robotText,
          message_id: data.id,
          extra: [],
        });
      }
    },
  },
};
</script>

<style lang="less">
.bubble {
  max-width: 75vw;
  position: relative;
  display: inline-block;
  text-justify: inter-ideograph;
  font-size: 5.0667vw;
  line-height: 6.9333vw;
  border-radius: 2.1333vw;
  padding: 4.2667vw 4vw;
  margin-left: 3.6vw;
  text-align: left;
  box-sizing: border-box;

  .outer {
    width: 100%;
    height: 30vw;
    .image {
      width: 100%;
      height: 100%;
    }
  }

  .link {
    display: flex;
    flex-direction: column;
    padding-top: 2vw;

    .problems {
      .item {
        font-weight: 400;
        font-size: 5.0667vw;
        margin-top: 2vw;

        .index {
          color: #444954;
          margin-right: 1.3333vw;
        }

        .item-text {
          color: #12a794;
        }
      }
    }

    .link-title {
      color: rgba(68, 73, 84, 0.7);
      font-size: 4.5vw;
    }
  }

  .link-web {
    width: 100%;
    font-size: 4.5vw;
    line-height: 4.5vw;
    color: #b6b7b7;
    text-decoration: none;
    word-break: break-all;
  }

  .arrow {
    width: 4.1067vw;
    height: 4.1067vw;
  }

  .left {
    position: absolute;
    .arrow;
    background-color: #fff;
    top: 6.4vw;
    left: -2.6667vw;
    transform: rotate(45deg) translateY(-50%);
    z-index: 0;
  }

  .right {
    position: absolute;
    .arrow;
    background-color: #fff;
    top: 50%;
    right: -2.6667vw;
    transform: rotate(-45deg) translateY(-50%);
  }

  .leftBottom {
    position: absolute;
    .arrow;
    background-color: #fff;
    bottom: -1.0667vw;
    left: 20%;
    right: -2.6667vw;
    transform: rotate(-45deg);
  }

  .rightBottom {
    position: absolute;
    .arrow;
    background-color: #fff;
    bottom: -1.0667vw;
    right: 20%;
    transform: rotate(45deg);
  }
}
</style>

<template>
  <div class="hint">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="item">
            <div class="bubble-one">
              <span class="content"
                >你好！我是肾小智，是张凌医生团队和微软（亚洲）互联网工程院联合开发的肾病科普人工智能问答机器人。</span
              >
              <span class="author">——小肾老师</span>
              <div class="arrow-right"></div>
            </div>
            <div class="little-person">
              <img src="../../assets/img/person.png" />
            </div>
            <!-- <div class="bubble-one">
              <span class="center-content">很高兴见到您！</span>
              <div class="arrow-left"></div>
            </div> -->
          </div>
        </div>
        <div class="swiper-slide">
          <div class="item">
            <!-- <div class="bubble-one">
              <span class="center-content">关于肾病知识，我已经学会了不少</span>
              <div class="arrow-right"></div>
            </div> -->
            <div class="bubble-one">
              <div class="content">
                <!-- <span>这些知识，来源于张凌医生团队多年的临床经验</span>
                <span>和他们为我精选的专业参考书。</span> -->
                关于肾病知识，我已经学会了不少，这些知识，来源于张凌医生团队多年的临床经验和他们为我精选的专业参考书。
              </div>
              <div class="arrow-right"></div>
            </div>
            <div class="little-person" id="person02">
              <img src="../../assets/img/person.png" />
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="item">
            <div class="bubble-one">
              <!-- <div class="content-tow">
                <span>但我不是医生</span>
                <span>有诊疗需求或紧急状况</span>
              </div> -->
              <div class="content">
                我是陪伴你了解肾病面对肾病的伙伴，诊疗方案，一定要咨询你的医生！
              </div>
              <div class="arrow-right"></div>
            </div>
            <div class="little-person" id="person03">
              <img src="../../assets/img/person.png" />
            </div>
            <!-- <div class="bubble-one">
              <div class="center-content">一定要找您的医生沟通哦！</div>
              <div class="arrow-left"></div>
            </div> -->
          </div>
        </div>
        <!-- <div class="swiper-slide">
          <div class="item">
            <div class="bubble-one">
              <div class="content-tow">
                <span>我刚上岗，经验不足</span>
                <span>但是你问我的问题</span>
              </div>
              <div class="arrow-right"></div>
            </div>
            <div class="bubble-one">
              <div class="content-tow">
                <span>我都会坚持学习的 </span>
                <span>欢迎随时来检验我的学习成果！</span>
              </div>
              <div class="arrow-left"></div>
            </div>
          </div>
        </div> -->
        <div class="swiper-slide">
          <div class="item">
            <div class="bubble-last">
              <span class="content"
                >我还在不停的学习中，你们问的越多我就学得越快，快来体验吧！</span
              >
              <div class="click-btn" @click="goHome">点击体验</div>
              <div class="arrow-right"></div>
            </div>
            <div class="little-person" id="person04">
              <img src="../../assets/img/person.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper-bundle.min.css";
import Bubble from "../../component/Bubble/index";

export default {
  name: "Hint",
  components: {
    Bubble,
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true, //循环
      pagination: {
        //分页符
        el: ".swiper-pagination",
      },
    });
  },
  methods: {
    goHome() {
      this.$router.push({
        path: "/home",
      });
    },
  },
};
</script>

<style lang="less">
@theme-color: #12a794;

.hint {
  position: fixed;
  top: 11.7333vw;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 5.0667vw;
  font-weight: 400;

  .item {
    // position: absolute;
    // left: 50%;
    // transform: translateX(-50%);
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 8.8vw;
    // margin: 0 auto;

    .little-person {
      position: absolute;
      top: 53.3333vw;
      right: 22vw;
      width: 32vw;

      > img {
        width: 100%;
        height: 100%;
        background-color: transparent;
      }
    }

    .bubble-last {
      // padding-top: 4.5333vw;
      width: 77.6vw;
      // height: 34.1333vw;
      background-color: @theme-color;
      border-radius: 2.1333vw;
      padding: 4.2667vw 5.3333vw 4.2667vw 5.3333vw;
      box-sizing: border-box;

      .content {
        display: block;
        width: 100%;
        text-align: center;
      }

      .click-btn {
        width: 61.8667vw;
        height: 12vw;
        text-align: center;
        line-height: 12vw;
        color: #020202;
        background-color: #ffd038;
        border-radius: 6vw;
        margin: 4.5333vw auto;
      }
    }
  }

  .bubble-one {
    position: relative;
    width: 77.6vw;
    // height: 35vw;
    background-color: @theme-color;
    border-radius: 2.1333vw;

    .content {
      // position: absolute;
      display: block;
      padding: 4.2667vw 5.3333vw 4.2667vw 5.3333vw;
      box-sizing: border-box;
      // top: 4.2667vw;
      // left: 5.3333vw;
    }

    .author {
      // position: absolute;
      display: block;
      text-align: right;
      padding-right: 5.0667vw;
      padding-bottom: 3.4667vw;
      // top: 12.5333vw;
      // left: 42.4vw;
    }

    .center-content {
      display: block;
      text-align: center;
      padding: 8vw 4.2667vw;
      box-sizing: border-box;
    }

    .content-tow {
      // position: absolute;
      width: 100%;
      text-align: center;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%, -50%);

      > span {
        display: block;
        height: 6.9333vw;
        text-align: center;
        padding: 5.3333vw 4.2667vw;
      }
    }
  }

  .swiper-container {
    width: 100%;
    height: 100%;
  }
}

.arrow-left {
  position: absolute;
  bottom: -1.3333vw;
  left: 15%;
  width: 4.1067vw;
  height: 4.1067vw;
  transform: rotateZ(45deg);
  background-color: @theme-color;
}

.arrow-right {
  position: absolute;
  bottom: -1.3333vw;
  right: 15%;
  width: 4.1067vw;
  height: 4.1067vw;
  transform: rotateZ(45deg);
  background-color: @theme-color;
}

.bubble-one:nth-child(1) {
  margin-bottom: 3.2vw;
}

#person02 {
  top: 44vw;
}

#person03 {
  top: 38vw;
}

#person04 {
  top: 58vw;
}
</style>

<template>
  <div class="welcome">
    <TopBar title="肾小智" />
    <div class="little-person">
      <Hint />
    </div>
  </div>
</template>

<script>
import TopBar from '../../component/TopBar/index'
import Bubble from '../../component/Bubble/index'
import Hint from '../../component/Hint/index'

export default {
  name: 'WelCome',
  components: {
    TopBar,
    Bubble,
    Hint
  }
}
</script>

<style lang="less" scoped>
.welcome {
  position: relative;
  width: 100%;
  height: 100%;

  .message {
    position: absolute;
    bottom: 20vw;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>